<template>
  <div></div>
</template>

<script setup lang="ts">
import {computed, onMounted, watch} from "vue";
import {clearObserverObj} from "../lib/functions";
import {useHead, useRoute, useSeoMeta, useState} from "#app";

const props = defineProps({
  mainHeadData: Object
});
const route = useRoute();

// Watch for changes in mainHeadData
watch(props.mainHeadData, () => {
  updateHead();
});

const updateHead = () => {
  const headData = clearObserverObj(props.mainHeadData);
  const headDataNoMeta = clearObserverObj(headData);
  if (headData.meta) {
    delete headDataNoMeta.meta;
    useSeoMeta(headData.meta);
  }

  useHead({
    ...headDataNoMeta,
    bodyAttrs: {
      class: computed(() => {
        const bodyClass = useState('body_class').value;
        return typeof bodyClass === 'string' ? bodyClass : '';
      }),
    }
  });
};


onMounted(() => {
  updateHead();
});

</script>


